<template>
    <div>
        <navTitle :title="title" :status="status"></navTitle>
        <div class="order">
            <div class="query">
                <van-field v-model="phone" class="query_input" type="number" maxlength="11" placeholder="请输入下单手机号" />
                <van-button type="danger" class="query_btn" :disabled="phone.length != 11" @click="queryOrder">查询订单</van-button>
            </div>
            <div class="tips">
                <div class="tips_text">温馨提示</div>
                <div class="tips_text">1.请输入您下单时的手机号查询物流信息</div>
                <div class="tips_text">2.订购成功后会在48小时之内发货，发货后才能查询到物流信息</div>
                <div class="tips_text">3.订单查询里支持进行申请退款</div>
            </div>
        </div>
    </div>
</template>

<script>
    import navTitle from "@/components/navTitle.vue";
    export default {
        components: {
            navTitle
        },
        data() {
            return{
                title: '订单',
                status: 0,
                phone: '',
            }
        },
        methods: {
			queryOrder() {
				this.$router.push({
                    path: '/queryOrder',
                    query: {
                        phone: this.phone,
                    }
                })
			}
		}
    }
</script>

<style lang="less" scoped>
.order{
    text-align: left;
    .query{
        width: 100vw;
        height: 200px;
        background: linear-gradient(0deg, #F8F8F8, #FFF7F8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .query_input{
            width: calc(100vw - 80px);
            height: 50px;
            font-size: 16px;
            background: #FFFFFF;
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.03);
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .query_btn{
            width: calc(100vw - 80px);
            height: 50px;
            color: #FFF;
            font-size: 16px;
            font-weight: bold;
            margin-top: 30px;
            border-radius: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button:after{
            border: transparent;
        }
    }
    .tips{
        font-size: 14px;
        color: #666666;
        padding: 0 40px;
        box-sizing: border-box;
        .tips_text{
            text-align: justify;
            margin: 5px 0;
        }
    }
}
</style>