<template>
    <div>
        <navTitle :title="title" :status="status"></navTitle>
        <div class="service">
            <div class="service_background">
                <img src="../../assets/background-service.png"></img>
            </div>
            <div class="content">
                <div class="service_phone" @click="toServiceLink(mallInfo.preSalesCustomerService)">
                    <div class="left">
                        <div class="icon">
                            <img src="../../assets/service-icon.png"></img>
                        </div>
                        <div class="text">售前咨询客服</div>
                    </div>
                    <div class="right">
                        <img src="../../assets/enter.png"></img>
                    </div>
                </div>
                <div class="service_phone" @click="toServiceLink(mallInfo.afterSalesCustomerService)">
                    <div class="left">
                        <div class="icon">
                            <img src="../../assets/service-icon.png"></img>
                        </div>
                        <div class="text">售后咨询客服</div>
                    </div>
                    <div class="right">
                        <img src="../../assets/enter.png"></img>
                    </div>
                </div>
                <div class="service_phone" v-show="mallInfo.complaintsHotline" @click="complaintTelephone(mallInfo.complaintsHotline, '拔打投诉电话')">
                    <div class="left">
                        <div class="icon">
                            <img src="../../assets/phone.png"></img>
                        </div>
                        <div class="text">拔打投诉电话</div>
                    </div>
                    <div class="right">
                        <img src="../../assets/enter.png"></img>
                    </div>
                </div>
                <div class="service_phone" v-if="mallInfo.afterSaleServiceHotline" @click="complaintTelephone(mallInfo.afterSaleServiceHotline, '拨打售后电话')">
                    <div class="left">
                        <div class="icon">
                            <img src="../../assets/phone.png"></img>
                        </div>
                        <div class="text">拨打售后电话</div>
                    </div>
                    <div class="right">
                        <img src="../../assets/enter.png"></img>
                    </div>
                </div>
            </div>
            <van-popup v-model="popup" position="bottom" :close-on-click-overlay="false" round closeable>
                <div class="popup-content">
                    <div class="title">{{ titlePopup }}</div>
                    <!-- <div class="close" @click="handleClose">×</div> -->
                    <div class="phone">{{ phone }}</div>
                    <div class="dial" @click="dialBtn">立即拨打</div>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import navTitle from "@/components/navTitle.vue";
    export default {
        components: {
            navTitle
        },
        props: {
			configInfoData: {
				type: Object,
				default: '',
			},
		},
		watch:{
			configInfoData(newval){
				console.log('newvalService', newval)
				this.mallInfo = newval;
			}
		},
        data() {
            return{
                title: '客服',
                status: 0,
                mallInfo: {
					preSalesCustomerService: '', // 售前客服链接
					afterSalesCustomerService: '', // 售后客服链接
					complaintsHotline: '', // 投诉热线电话
					afterSaleServiceHotline: '', // 售后电话
				},
                popup: false, // 弹出层
                titlePopup: '',
				phone: '',
            }
        },
        methods: {
            // 跳转链接
            toServiceLink(url){
                console.log('url', url);
                if(url.indexOf('https://') != -1 || url.indexOf('http://') != -1 || url.indexOf('www.') != -1){
                    window.location.href = url
                } else {
                    this.$toast('客服链接配置出错了');
                }
            },
			// 打开投诉电话弹窗
			complaintTelephone(phone, title){
                console.log('phone111', phone);
                console.log('title', title);
                this.phone = phone;
				this.titlePopup = title;
				this.popup = true;
			},
			// 立即拨打电话
			dialBtn(){
				console.log('拨打电话');
				this.popup = false;
                window.location.href = 'tel://' + this.phone
			}
		}
    }
</script>

<style lang="less" scoped>
.service{
    .service_background{
        width: 100vw;
        height: 150px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .content{
        padding: 0 20px;
        margin-top: -20px;
        box-sizing: border-box;
        .service_phone{
            padding: 20px;
            margin: 10px 0;
            border-radius: 10px;
            background: #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
                display: flex;
                align-items: center;
                .icon{
                    width: 30px;
                    height: 30px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .text{
                    font-size: 16px;
                    color: #000000;
                    margin-left: 15px;
                }
            }
            .right{
                width: 20px;
                height: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .popup-content{
        position: relative;
        padding: 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
            font-size: 16px;
            color: #333333;
            text-align: center;
        }
        .close{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 40px;
            height: 40px;
            color: #666666;
            font-size: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .phone{
            font-weight: bold;
            font-size: 30px;
            color: #000000;
            margin: 20px ;
        }
        .dial{
            color: #FFF;
            font-weight: bold;
            font-size: 16px;
            padding: 10px 30px;
            border-radius: 50px;
            background: #F6555A;
        }
    }
}
</style>