<template>
    <div>
        <navTitle :title="title"></navTitle>
        <div class="result">
            <div class="icon">
                <img src="../../assets/refund.png"></img>
            </div>
            <div class="icon-tips">退款申请提交成功</div>
            <div class="refund">退款将在1-7个工作日内退还</div>
            <div class="back-to-home" @click="toHome">首页</div>
        </div>
    </div>
</template>
  
<script>
import navTitle from "@/components/navTitle.vue";
export default {
    components: {
        navTitle
    },
    data() {
        return {
            title: '退款详情',
        };
    },
    methods: {
        toHome() {
            let code = window.localStorage.getItem('shopCode');
            this.$router.push({
                path: `/mall/${code}`,
                query: {
                    selectIndex: 0,
                }
            })
        },
    },
};
</script>
  
<style lang="less" scoped>
.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    font-size: 16px;
    .icon {
        width: 44px;
        height: 44px;
        margin-top: 100px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .icon-tips {
        font-size: 16px;
        color: #333333;
        margin-top: 30px;
    }
    .refund {
        font-size: 16px;
        color: #999999;
        margin-top: 5px;
    }
    .back-to-home {
        width: 150px;
        height: 45px;
        font-size: 16px;
        color: #FFF;
        font-weight: bold;
        border-radius: 50px;
        margin-top: 80px;
        background: #F6555A;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
  