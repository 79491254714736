<template>
    <div>
        <navTitle :title="title"></navTitle>
        <div class="result">
            <div class="icon">
                <img src="../../assets/submit-success.png" ></img>
            </div>
            <div class="icon-tips">订单提交成功！</div>
            <div class="order-number">订单编号：{{ orderNo }}</div>
            <div class="back-to-home" @click="toHome">首页</div>
        </div>
    </div>
</template>

<script>
    import navTitle from "@/components/navTitle.vue";
    export default {
        components: {
			navTitle
		},
        data() {
            return{
                title: '订单详情',
                orderNo: '',
            }
        },
        mounted(){
            this.orderNo = this.$route.query.orderNo
        },
        methods: {
            // 返回首页
            toHome(){
                let code = window.localStorage.getItem('shopCode');
                console.log('返回首页的code', code);
                this.$router.push({
                    path: `/mall/${code}`
                })
            }
		}
    }
</script>

<style lang="less" scoped>
.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    font-size: 16px;
    .icon {
		width: 115px;
		height: 65px;
        margin-top: 100px;
		img{
			width: 100%;
			height: 100%;
		}
    }
    .icon-tips {
		font-size: 18px;
		color: #333333;
        margin-top: 30px;
    }
    .order-number {
		font-size: 16px;
		color: #999999;
        margin-top: 5px;
    }
    .back-to-home {
		width: 150px;
		height: 45px;
		font-size: 16px;
		color: #FFF;
		font-weight: bold;
		border-radius: 50px;
		margin-top: 80px;
		background: #F6555A;
		display: flex;
		justify-content: center;
		align-items: center;
    }
}
</style>