<template>
    <div>
        <navTitle :title="title"></navTitle>
        <div class="queryRefund" :style="{'height': windowHeight - 40 + 'px'}">
            <div class="queryRefund_content">
                <div class="queryRefund_item">
                    <div class="left_text">订单编号</div>
                    <div class="orderNo">{{ orderNo }}</div>
                </div>
                <div class="queryRefund_item">
                    <div class="left_text">退款金额</div>
                    <div class="price">{{ price }}元</div>
                </div>
            </div>
            <div class="submitRefund" @click="submit">提交退款</div>
        </div>
    </div>
</template>

<script>
    import { refund } from '@/api/api'
    import navTitle from "@/components/navTitle.vue";
	export default{
        components: {
			navTitle
		},
		data() {
			return {
                title: '申请退款',
                windowHeight: '',
				orderNo: '',
				price: '',
			}
		},
        mounted(){
            console.log('this.$route.params', this.$route.query);
            let { orderNo, price } = this.$route.query;
            this.orderNo = orderNo;
            this.price = price;
            this.windowHeight = document.body.clientHeight
        },
		methods: {
			// 提交退款
			submit() {
                let params = {
                    orderNo: this.$route.query.orderNo,
                }
                refund(params).then((res) => {
                    console.log('退款的res', res);
                    if(res.code == 200 || res.code == 0){
                        this.$router.push({
                            path: '/submitRefund'
                        })
                    } else  {
                        this.$toast(res.message);
                    }
                })
			}
		}
	}
</script>

<style lang="less" scoped>
.queryRefund{
    position: relative;
    width: 100vw;
    padding: 10px;
    box-sizing: border-box;
    background: #f8f8f8;
    .queryRefund_content{
        background: #FFFFFF;
        border-radius: 8px;
        .queryRefund_item{
            padding: 15px 10px;
            display: flex;
            justify-content: space-between;
            .left_text{
                font-size: 14px;
                color: #666666;
            }
            .orderNo{
                font-size: 14px;
                color: #333333;
            }
            .price{
                font-weight: bold;
                font-size: 14px;
                color: #F6555A;
            }
        }
    }
    .submitRefund{
        position: absolute;
        left: 30px;
        bottom: 30px;
        width: calc(100vw - 60px);
        height: 45px;
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        background: #F6555A;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>