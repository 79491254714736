<template>
    <div>
        <div class="nav">
            <div class="back" @click="toBack()" v-show="status != 0">
                <img src="@/assets/fanhui.png">
            </div>
            <div class="nav_center">{{ title }}</div>
        </div>
        <div class="top"></div>
    </div>
</template>

<script>
export default{
    props: {
        title: {
            type: String,
        },
        status: {
            type: String | Number,
        }
    },
    data(){
        return{

        }
    },
    mounted(){

    },
    methods: {
        // 后退
        toBack(){
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="less" scoped>
.nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 40px;
    background: #F8F8F8;
    display: flex;
    align-items: center;
    z-index: 100;
    .back{
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        img{
            width: 18px;
            height: 20px;
        }
    }
    .nav_center{
        position: absolute;
        width: 100vw;
        height: 40px;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
    }
}
.top{
    height: 40px;
}
</style>