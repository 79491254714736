<template>
	<div>
		<navTitle :title="title"></navTitle>
		<div class="queryOrder">
			<div class="orderList" v-for="item in orderList" v-show="orderList.length">
				<div class="orderList_content">
					<div class="orderList_item_copy">
						<div class="left">订单编号</div>
						<div class="right_copy">
							<div class="text">{{ item.orderNo }}</div>
							<div class="copy" @click="handleCopy(item.orderNo)">
								<img src="../../assets/copy.png"></img>
							</div>
						</div>
					</div>
				</div>
				<div class="orderList_content">
					<div class="orderList_item">
						<div class="left">下单时间</div>
						<div class="text">{{ item.createTime }}</div>
					</div>
					<div class="orderList_item">
						<div class="left">订单状态</div>
						<div class="text" :class="item.orderStatus == '已失败' ? 'reason' : ''">{{ item.orderStatus }}</div>
					</div>
					<div class="orderList_item" v-show="item.orderStatus == '已失败'">
						<div class="left">失败原因</div>
						<div class="reason">{{ item.reason }}</div>
					</div>
				</div>
				<div class="orderList_content">
					<div class="orderList_item">
						<div class="left">物流公司</div>
						<div class="text">{{ item.logisticsCompany ? item.logisticsCompany : '--' }}</div>
					</div>
					<div class="orderList_item_copy">
						<div class="left">物流单号</div>
						<div class="right_copy">
							<div class="text">{{ item.logisticsNo ? item.logisticsNo : '--'}}</div>
							<div class="copy" @click="handleCopy(item.orderNo)" v-if="item.logisticsNo">
								<img src="../../assets/copy.png"></img>
							</div>
						</div>
					</div>
					<div class="orderList_item">
						<div class="left">发货时间</div>
						<div class="text">{{ item.startTime ? item.startTime : '--'}}</div>
					</div>
					<div class="orderList_item">
						<div class="left">物流状态</div>
						<div class="text">{{ item.logisticsStatus ? item.logisticsStatus : '--' }}</div>
					</div>
				</div>
				<div class="price">
					<div class="price_text">付款金额：{{ item.amount }}元</div>
					<div class="refund" @click="handleRefund(item)" v-if="item.allowRefund">申请退款</div>
				</div>
			</div>
			<div class="no_orderData" :style="{height: windowHeight - 20 + 'px'}" v-show="!orderList.length">暂无订单数据</div>
		</div>
	</div>
</template>

<script>
    import { getQueryOrderList } from "@/api/api";
    import navTitle from "@/components/navTitle.vue";
	export default{
		components: {
			navTitle
		},
		data(){
			return {
				title: '订单查询',
				windowHeight: '',
				orderList: []
			}
		},
		mounted(){
            this.windowHeight = window.localStorage.getItem('windowHeight');
			this.getOrderList()
		},
		methods: {
			// 获取用户订单列表
			getOrderList(){
				console.log('this.$route.query.phone', this.$route.query.phone);
				let params = {
					shopCode: window.localStorage.getItem('shopCode'),
					mobile: this.$route.query.phone,
				}
				getQueryOrderList(params).then((res) => {
					console.log('查询订单列表的res', res)
					if(res.code == 200 || res.code == 0){
						this.orderList = res.rows;
						console.log('this.orderList', this.orderList.length);
					} else {
						this.$toast(res.message);
					}
				});
			},
			// 复制
			handleCopy(data){
				// 获取要复制的文本
				const textToCopy = data;
				// 创建一个临时的textarea元素
				const textarea = document.createElement('textarea');
				textarea.value = textToCopy;
				textarea.style.position = 'fixed';
				document.body.appendChild(textarea);
				// 选中文本
				textarea.select();
				textarea.setSelectionRange(0, textarea.value.length);
				// 复制文本到剪贴板
				document.execCommand('copy');
				// 清理临时元素
				document.body.removeChild(textarea);
				// 提示复制成功
				this.$toast('已复制到剪贴板');
			},
			// 退款
			handleRefund(item){
				this.$router.push({
                    path: '/queryRefund',
                    query: {
                        orderNo: item.orderNo,
                        price: item.amount,
                    }
                })
			}
		}
	}
</script>

<style lang="less" scoped>
.queryOrder{
    background: #F8F8F8;
    padding: 0 10px 10px;
    box-sizing: border-box;
    .orderList{
        margin: 0 0 10px;
        border-radius: 10px;
        background: #FFF;
        .orderList_content{
            padding: 5px 10px;
            border-bottom: 2px solid #F8F8F8;
            .orderList_item_copy{
                margin: 10px 0;
                display: flex;
                justify-content: space-between;
                .left{
                    font-size: 14px;
                    color: #666666;
                }
                .right_copy{
                    display: flex;
                    align-items: center;
                    .text{
                        font-size: 14px;
                        color: #333333;
                    }
                    .copy{
                        width: 12px;
                        height: 12px;
                        margin-left: 5px;
                        display: flex;
                        align-items: center;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .orderList_item{
                margin: 10px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left{
                    font-size: 14px;
                    color: #666666;
                }
                .text{
                    font-size: 14px;
                    color: #333333;
                }
                .reason{
                    font-size: 14px;
                    color: #F6555A;
                }
            }
        }
        .price{
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .price_text{
                font-weight: bold;
                font-size: 14px;
                color: #F6555A;
            }
            .refund{
                font-size: 14px;
                color: #666666;
                padding: 5px 20px;
                border-radius: 50px;
                border: 1px solid rgba(102,102,102,0.56);
            }
        }
    }
	.no_orderData{
		padding: 50px 0;
		box-sizing: border-box;
		background: #F8F8F8;
	}
}
</style>