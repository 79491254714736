<template>
    <div class="payOrder" :style="{ 'height': windowHeight + 'px' }">
       <div class="payPrice">
            <div class="payPrice_text">需支付</div>
            <div class="price">￥<div class="price_text">0.01</div></div>
       </div>
       <div class="payType">
            <van-radio-group v-model="checkedPay" @change="radioChange">
                <van-radio class="payType_item" :class="checkedPay == 1 ? 'active' : ''" name="1" checked-color="#F6555A" label-position="left">
                    <div class="payType_left">
                        <div class="payType_img">
                            <img src="@/assets/wxpay.png" />
                        </div>
                        <span class="payType_name">微信支付</span>
                    </div>
                </van-radio>
                <van-radio class="payType_item" :class="checkedPay == 2 ? 'active' : ''" name="2" checked-color="#F6555A" label-position="left">
                    <div class="payType_left">
                        <div class="payType_img">
                            <img src="@/assets/alipay-icon.png" />
                        </div>
                        <span class="payType_name">支付宝支付</span>
                    </div>
                </van-radio>
            </van-radio-group>
       </div>
       <div class="agreement_Pay">
            <div class="radio">
                <van-checkbox v-model="checkedAgreement" checked-color="#F6555A" icon-size="16px"></van-checkbox>
                <div class="agreement">我同意《购买守则》,《取消政策》和《退款政策》,我也同意支付以下所示的总金额(含服务费)。</div>
            </div>
            <van-button type="danger" :disabled="!checkedAgreement" class="payBtn" @click="payBtn">确认支付&nbsp;&nbsp;&nbsp;￥0.01</van-button>
       </div>
       
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            windowHeight: '', // 页面显示高度
            checkedPay: '1', // 支付方式单选
            checkedAgreement: false, // 协议单选
        };
    },
    mounted(){
        this.windowHeight = document.body.clientHeight;
    },
    methods: {
        // 支付类型单选
        radioChange(e){
            this.checkedPay = e;
        },
        // 确认支付
        payBtn(){
            console.log('支付');
        }
    },
};
</script>
  
<style lang="less" scoped>
.payOrder {
    position: relative;
    text-align: left;
    background: #F8F8F8;
    .payPrice{
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .payPrice_text{
            font-size: 16px;
            color: #999999;
        }
        .price{
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            margin: 15px 0;
            display: flex;
            align-items: flex-end;
            .price_text{
                font-size: 28px;
                color: #333333;
                line-height: 28px;
            }
        }
    }
    .payType{
        padding: 0 20px;
        .payType_item{
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .payType_left{
                display: flex;
                align-items: center;
                .payType_img{
                    width: 20px;
                    height: 20px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .payType_name{
                    margin-left: 10px;
                }
            }
        }
        .active{
            border-radius: 10px;
            background: #FFF;
        }
    }
    .agreement_Pay{
        position: absolute;
        bottom: 20px;
        padding: 0 20px;
        .radio{
            width: calc(100vw - 40px);
            display: flex;
            align-items: flex-start;
            .agreement{
                width: calc(100vw - 60px);
                font-size: 13px;
                color: #999999;
                text-align: justify;
                margin: -2px 0 0 5px;
            }
        }
        .payBtn{
            width: calc(100vw - 40px);
            height: 45px;
            color: #FFF;
            font-weight: bold;
            font-size: 16px;
            border-radius: 50px;
            margin-top: 10px;
        }
    }
}
</style>
  